<template>
  <div id="Orders_module">
    <div class="d-flex align-center px-4 py-2">
      <div class="font-weight-bold primaryColor" id="order_label">Orders</div>
      <v-progress-circular
        class="ml-2"
        indeterminate
        v-if="orderLoader"
        size="18"
        :width="2"
        color="blue"
      ></v-progress-circular>
    </div>

    <v-row class="mb-2 mx-0 mt-0 px-4">
      <v-slide-group v-model="infoType" class="pa-0" mandatory hide-arrows>
        <v-slide-item
          v-for="(item, i) in tabList"
          :key="i"
          v-bind:value="item.name"
          v-slot="{ active, toggle }"
        >
          <v-tooltip top color="toolTipColor toolTipTop">
            <template v-slot:activator="{ on, attrs }">
              <span
                @click="
                  $router
                    .push({
                      path: 'orders',
                      query: { tab: infoType.trim().toString() },
                    })
                    .catch(() => {})
                "
              >
                <v-btn
                  depressed
                  :color="
                    active
                      ? 'activeColor white--text'
                      : 'unActiveColor black--text'
                  "
                  height="26"
                  min-width="50"
                  class="fsize12 mr-2 rounded-sm text-center text-capitalize"
                  :value="item.name"
                  @click="toggle"
                  v-bind="attrs"
                  v-on="on"
                  :id="`${item.name}_menu`"
                >
                  {{ item.name }}
                  <span v-if="item.length != 0"
                    >&nbsp; ({{ item.length }})</span
                  >
                </v-btn>
              </span>
            </template>
            <span>{{ item.toolTip }}</span>
          </v-tooltip>
        </v-slide-item>
      </v-slide-group>

      <v-spacer></v-spacer>
       <span
        class="float-right d-flex align-center"
        id="searchBarAndIcons"
        v-if="this.showOrderlist.length > 0"
      >
        <span class="search-box mx-2" v-if="!$store.state.isMobileView">
          <span class="searchwhole">
            <customIcon  :name="'search'" class="searchimg" :width="'16'" :height="'16'" :color="$store.state.amogaColor"  />
            <span class="searchinput">
              <input id="orderSearchInp"
                class="placeholdsearch"
                placeholder="Search"
                autocorrect="off"
                v-model="searchorder"
                oninput="this.value = this.value.toUpperCase()"
              />
            </span>
          </span>
        </span>
        <v-tooltip top color="toolTipColor toolTipTop">
          <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" v-if="!$store.state.isMobileView" @click="checkDownload(infoType, 'orders' , showOrderlist)">
          <customIcon  :name="'download'" class="mx-1 cursor d-flex" :width="'21'" :height="'21'" :color="$store.state.amogaColor"  />
          </span>
          </template>
          <span>Download</span>
        </v-tooltip>
         <v-tooltip top color="toolTipColor toolTipTop">
          <template v-slot:activator="{ on, attrs }">
           <span id="order_refresh" v-bind="attrs" v-on="on" @click="$store.dispatch('order/getOrderList')">
          <customIcon  :name="'refresh'" class="mx-1 cursor d-flex" :width="'16'" :height="'16'" :color="$store.state.amogaColor"  />
          </span>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </span>
    </v-row>
    <v-divider class="mx-4 mb-2"></v-divider>
    <!-- v-if="showList.length != 0" -->
    <v-card id="order_module_card"
      class="ma-4"
      v-if="
        (showOrderlist.length > 0 &&
          this.searchorder == '' &&
          !this.orderLoader) ||
        (orderSearchFilter.length > 0 &&
          this.searchorder !== '' &&
          !this.orderLoader)
      "
    >
      <v-simple-table v-if="!$store.state.isMobileView"
        transition="fade-transition"
        id="order_table"
        :class="{ overflowinherit: isDropped }"
      >
        <thead id="order_table_thead">
          <tr class="tableRow" id="order_table_thead_tr">
            <th
              v-if="getInfoType == 'Pending'"
              class="text-left d-flex align-center" id="order_table_thead_check_box"
            >
              <!-- <v-checkbox :ripple="false" class="ma-0" v-model="checkBoxSelectAll" color="#1f3565" hide-details></v-checkbox> -->
              <input
                type="checkbox"
                v-model="checkBoxSelectAll"
                class="tableCheckBox"
              />
            </th>
            <th class="text-left tableHeader" id="order_table_thead_time">Time</th>
            <th class="text-center tableHeader" id="order_table_thead_type">Type</th>
            <th class="text-left tableHeader" id="order_table_thead_inst">Instrument</th>
            <th class="text-left tableHeader" id="order_table_thead_pro">Product</th>
            <th class="text-right tableHeader" id="order_table_thead_qty">Qty.</th>
            <th class="text-right tableHeader" id="order_table_thead_ltp" v-if="getInfoType == 'Pending'">
              LTP
            </th>
            <th class="text-right tableHeader" id="order_table_thead_price">Price</th>
            <th
              class="text-center tableHeader" id="order_table_thead_status"
              v-if="getInfoType != 'Trade Book'"
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody id="order_table_tbody"
          class=""
          @mouseleave="
            currentIndex = -1;
            isDropped = false;
          "
        >
          <tr
            class="tableRow"
            v-for="(item, index) in this.searchorder == ''
              ? this.showOrderlist
              : orderSearchFilter"
            :key="index"
            @mouseenter="
              isDropped = false;
              currentIndex = index;
            "
            :id="`${index}_order_table_tbody_tr`"
          >
            <td class="px-4 py-2" v-if="getInfoType == 'Pending'" :id="`${index}_order_table_tbody_check_box`">
              <!-- <v-checkbox :ripple="false" class="ma-0" v-model="selectedCheckbox" :value="item" color="#1f3565" hide-details></v-checkbox> -->
              <input
                type="checkbox"
                v-model="selectedCheckbox"
                :value="item"
                class="tableCheckBox"
              />
            </td>

            <td :id="`${index}_order_table_tbody_time`"
              class="text-left primaryColor px-4 py-2 tableContent"
              v-if="getInfoType != 'Trade Book'"
            >
              {{
                item.OrderedTime != null
                  ? item.OrderedTime.split(" ")[1]
                  : item.OrderedTime
              }}
            </td>
            <td :id="`${index}_order_table_tbody_time`" lass="text-left primaryColor px-4 py-2 tableContent" v-else>
              {{ item.Time.split(" ")[1] }}
            </td>
            <td :id="`${index}_order_table_tbody_order_type`" class="text-center px-4 py-2 tableContent">
              <button
                class="rounded-sm text-capitalize fsize10 px-3 py-1"
                :class="item.Trantype == 'B' ? 'buycolor' : 'sellcolor'"
              >
                {{ item.Trantype == "B" ? "BUY" : "SELL" }}
              </button>
            </td>
            <td :id="`${index}_order_table_tbody_scrip_name`" class="text-left pos-reletive px-4 py-2 tableContent">
              {{
                item.formatScripName == null ? item.Trsym : item.formatScripName
              }}
              <span class="fsize10 secondaryColor">{{ item.Exchange }}</span>
              <div
              v-if="index == currentIndex"
                class=" dropdown position-absolute right-0"
              
              >
             
              <!-- <v-tooltip top color="toolTipColor toolTipTop">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-if="getInfoType != 'Pending'" class=" 
                     
                      mr-2
                        fsize12
                    
                        text-capitalize
                      hoverBtn
                      rounded-sm
                         sell" 
                         outlined 
                         depressed
                         min-width="50"
                      max-width="50"
                      height="23"
                         :id="`${index}_mw_list_scrip_sell`"     @click="
                        isDropped = false;
                        callOrderWindow(
                          item.Trantype == 'B' ? 'buy' : 'sell',
                          item,
                          'repeat'
                        );
                      " v-bind="attrs" v-on="on">Repeat</v-btn>
                    </template>
                    <span>Repeat</span>
                  </v-tooltip> -->

                <v-tooltip top color="toolTipColor toolTipTop">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn :id="`${index}_order_table_tbody_opt`"
                      @mouseover="getWindowHeight(`${index}_order_table_tbody_opt`)"
                      @click="isDropped = !isDropped"
                      min-width="50"
                      max-width="50"
                      height="23"
                      outlined
                      depressed
                      v-bind="attrs"
                      v-on="on"
                      class="
                      
                     
                     
                        fsize12
                        text-capitalize
                        border-0075e1
                        rounded-sm
                        color-0075e1
                        background-white
                      "
                      >More
                    </v-btn>
                  </template>
                  <span>Options</span>
                </v-tooltip>

                <transition name="slide">
                  <ul :id="`${index}_order_table_tbody_opt_ul`"
                    v-if="currentIndex == index && isDropped"
                    :class="isRotate ? 'bottom25' : ''"
                    class="pa-0 list expectMkWatchMoreList"
                  >
                    <li :id="`${index}_mw_list_scrip_sell`"
                      v-if="getInfoType != 'Pending'" @click="isDropped = false;callOrderWindow(item.Trantype == 'B' ? 'buy' : 'sell',
                          item, 'repeat')" class="primaryColor fsize12 cursor"> Repeat
                    </li>
                    <li :id="`${index}_order_table_tbody_opt_modify_li`"
                      v-if="getInfoType == 'Pending'"
                      @click="
                        isDropped = false;
                        callOrderWindow(
                          item.Trantype == 'B' ? 'buy' : 'sell',
                          item,
                          'modify'
                        );
                      "
                      class="primaryColor fsize12 cursor"
                    >
                      Modify
                    </li>
                    <li :id="`${index}_order_table_tbody_opt_buy_li`"
                      @click="
                        callOrderWindow('buy', item);
                        isDropped = false;
                      "
                      v-if="getInfoType != 'Pending'"
                      class="primaryColor fsize12 cursor"
                    >
                      Buy
                    </li>
                    <li :id="`${index}_order_table_tbody_opt_sell_li`"
                      @click="
                        callOrderWindow('sell', item);
                        isDropped = false;
                      "
                      v-if="getInfoType != 'Pending'"
                      class="primaryColor fsize12 cursor"
                    >
                      Sell
                    </li>
                    <li :id="`${index}_order_table_tbody_opt_cancel_li`"
                      v-if="getInfoType == 'Pending'"
                      @click="
                        cancelOrderPopup(item, index);
                        isDropped = false;
                      "
                      class="primaryColor fsize12 cursor"
                    >
                      Cancel
                    </li>
                    <li :id="`${index}_order_table_tbody_opt_info_li`"
                      @click="
                        getInfo(item, index);
                        isDropped = false;
                      "
                      class="primaryColor fsize12 cursor"
                    >
                      Info
                    </li>
                  </ul>
                </transition>
              </div>
            </td>
            <td class="text-left px-4 py-2 tableContent" :id="`${index}_order_table_tbody_pcode`">{{ item.Pcode }}</td>
            <td
              class="text-right px-4 py-2 tableContent" :id="`${index}_order_table_tbody_qty`"
              v-if="getInfoType != 'Trade Book'"
            >
              {{ item["Fillshares"] + " / " + item["Qty"] }}
            </td>
            <td class="text-right px-4 py-2 tableContent" v-else :id="`${index}_order_table_tbody_qty`">
              {{ item["Filledqty"] + " / " + item["Qty"] }}
            </td>
            <td :id="`${index}_order_table_tbody_ltp`"
              class="text-right px-4 py-2 tableContent"
              v-if="getInfoType == 'Pending'"
            >
              {{
                isNaN(parseFloat(item.ltp))
                  ? item.ltp
                  : item.Exchange == "CDS" || item.Exchange == "BCD"
                  ? parseFloat(item.ltp).toFixed(4)
                  : parseFloat(item.ltp).toFixed(2)
              }}
            </td>
            <td :id="`${index}_order_table_tbody_prc`"
              class="text-right px-4 py-2 tableContent"
              v-if="getInfoType != 'Trade Book'"
            >
              {{
                item.Exchange == "CDS" || item.Exchange == "BCD"
                ? parseFloat(
                      Number(item.Avgprc) == 0 ? item.Prc : item.Avgprc
                    ).toFixed(4)
                  : parseFloat(
                      Number(item.Avgprc) == 0 ? item.Prc : item.Avgprc
                    ).toFixed(2)
              }}<span v-if="parseFloat(item.Trgprc) > 0"
                >&nbsp;/&nbsp;{{
                  item.Exchange == "CDS" || item.Exchange == "BCD"
                    ? parseFloat(item["Trgprc"]).toFixed(4)
                    : parseFloat(item["Trgprc"]).toFixed(2)
                }}
                trg.</span
              >
            </td>
            <td class="text-right px-4 py-2 tableContent" v-else :id="`${index}_order_table_tbody_prc`">
              {{
                item.Exchange == "CDS" || item.Exchange == "BCD"
                  ? parseFloat(item.Price).toFixed(4)
                  : parseFloat(item.Price).toFixed(2)
              }}
            </td>
            <td :id="`${index}_order_table_tbody_reason`"
              class="text-center px-4 py-2 tableContent"
              v-if="getInfoType != 'Trade Book'"
            >
              <v-tooltip
                transition="scroll-y-reverse-transition"
                :disabled="
                  item.RejReason == '--' ||
                  item.RejReason == '' ||
                  item.RejReason == null
                "
                max-width="300"
                top
                color="toolTipColor toolTipTop"
              >
                <template v-slot:activator="{ on, attrs }">
                  <button
                    v-bind="attrs"
                    v-on="on"
                    class="rounded text-capitalize fsize10 min-width-100"
                    :class="
                      item.Status == 'rejected'
                        ? 'rejected-msg'
                        : item.Status == 'complete'
                        ? 'completed-msg'
                        : 'cancelled-msg'
                    "
                  >
                    {{
                      (item.Status == "cancelled after market order"
                        ? "Cancelled AMO"
                        : item.Status ==
                          "modify after market order req received"
                        ? "Modified AMO"
                        : item.Status == "after market order req received"
                        ? "AMO"
                        : item.Status
                      ).toUpperCase()
                    }}
                  </button>
                </template>
                <span class="fsize12">{{ item.RejReason }}</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
        <tfoot v-if="selectedCheckbox.length > 0 && getInfoType == 'Pending'" id="order_table_tfoot">
          <tr class="tableRow" id="order_table_tfoot_tr">
            <td class="text-left tableContent" colspan="9">
              <v-btn
                min-width="80"
                @click="$store.commit('order/setMultipleCancelDialog', true)"
                depressed
                height="32"
                :color="$store.state.buttonThemeColor"
                class="text-capitalize white--text fsize14 ml-0 ma-2" id="order_table_tfoot_cancel_all_btn"
                >{{
                  selectedCheckbox.length == 1
                    ? "Cancel Order"
                    : `Cancel Orders (${selectedCheckbox.length})`
                }}</v-btn
              >
            </td>
          </tr>
        </tfoot>
      </v-simple-table>

         <div v-if="$store.state.isMobileView">
        <div
          class="row ma-0 px-3 py-2 border-bottom-mwSearch"
          v-for="(item, index) in this.searchorder == ''
            ? this.showOrderlist
            : orderSearchFilter"
          :key="index" @click="callBottomSheet(item)"
          :id="`${index}_order_div_row`"
        >
          <div class="col-6 pa-0 ma-0 d-flex align-center">
            <button
              class="rounded-sm text-capitalize fsize10 px-3 py-1"
              :class="item.Trantype == 'B' ? 'buycolor' : 'sellcolor'" :id="`${index}_order_div_orderType`"
            >
              {{ item.Trantype == "B" ? "BUY" : "SELL" }}
            </button>

            <label :id="`${index}_order_div_qty`"
              for=""
              v-if="getInfoType != 'Trade Book'"
              class="pl-2 fsize12"
            >
              {{ item["Fillshares"] + " / " + item["Qty"] }}</label
            >
          </div>
          <div class="col-6 pa-0 ma-0 d-flex justify-end align-center">
            <div class="mr-2 fsize12" :id="`${index}_order_div_time`">
              {{
                getInfoType != "Trade Book"
                  ? item.OrderedTime != null
                    ? item.OrderedTime.split(" ")[1]
                    : item.OrderedTime
                  : item.Time.split(" ")[1]
              }}
            </div>
            <button :id="`${index}_order_div_reason_msg`"
              v-if="getInfoType != 'Trade Book'"
              class="rounded text-capitalize fsize10 min-width-100"
              :class="
                item.Status == 'rejected'
                  ? 'rejected-msg'
                  : item.Status == 'complete'
                  ? 'completed-msg'
                  : 'cancelled-msg'
              "
            >
              {{
                (item.Status == "cancelled after market order"
                  ? "Cancelled AMO"
                  : item.Status == "modify after market order req received"
                  ? "Modified AMO"
                  : item.Status == "after market order req received"
                  ? "AMO"
                  : item.Status
                ).toUpperCase()
              }}
            </button>
          </div>
          <div
            class="
              row
              pa-0
              fsize14
              mx-0
              my-2
              d-flex
              w-100
              justify-space-between
            "
          >
            <div :id="`${index}_order_div_scrip_name`">
              {{
                item.formatScripName == null ? item.Trsym : item.formatScripName
              }}
            </div>
            <div :id="`${index}_order_div_scrip_prc`" v-if="getInfoType != 'Trade Book'">
              {{
                item.Exchange == "CDS" || item.Exchange == "BCD"
                ? parseFloat(
                        Number(item.Avgprc) == 0 ? item.Prc : item.Avgprc
                      ).toFixed(4)
                    : parseFloat(
                        Number(item.Avgprc) == 0 ? item.Prc : item.Avgprc
                      ).toFixed(2)
              }}<span v-if="parseFloat(item.Trgprc) > 0"
                >&nbsp;/&nbsp;{{
                  item.Exchange == "CDS" || item.Exchange == "BCD"
                    ? parseFloat(item["Trgprc"]).toFixed(4)
                    : parseFloat(item["Trgprc"]).toFixed(2)
                }}
                trg.</span
              >
            </div>
          </div>
          <div class="row ma-0 d-flex justify-space-between fsize12">
            <div v-if="getInfoType == 'Pending'">
              <span :id="`${index}_order_div_scrip_ex`">{{ item.Exchange }}</span>
              <span class="px-2" :id="`${index}_order_div_scrip_pcode`">{{ item.Pcode }}</span>
              <span :id="`${index}_order_div_scrip_prcType`">{{ item.Prctype }}</span>
            </div>
            <div v-if="getInfoType == 'Pending'">
              <span :id="`${index}_order_div_scrip_lp_label`">LTP</span>
              <span class="px-2" :id="`${index}_order_div_scrip_lp_val`">{{
                isNaN(parseFloat(item.ltp))
                  ? item.ltp
                  : item.Exchange == "CDS" || item.Exchange == "BCD"
                  ? parseFloat(item.ltp).toFixed(4)
                  : parseFloat(item.ltp).toFixed(2)
              }}</span>
            </div>

            <div v-if="getInfoType !== 'Pending'"  :id="`${index}_order_div_scrip_ex`">
              <span>{{ item.Exchange }}</span>
            </div>
            <div v-if="getInfoType !== 'Pending'">
              <span class="px-2"  :id="`${index}_order_div_scrip_pcode`">{{ item.Pcode }}</span>
              <span  :id="`${index}_order_div_scrip_prctype`">{{ item.Prctype }}</span>
            </div>
          </div>
        </div>
      </div>  
    </v-card>

    <div
      class="d-flex flex-column justify-center align-center vh-70"
      v-if="
        (!!showOrderlist &&
          showOrderlist.length == 0 &&
          this.searchorder == '' &&
          !this.orderLoader) ||
        (!!showOrderlist &&
          orderSearchFilter.length == 0 &&
          this.searchorder !== '' &&
          !this.orderLoader)
      "
    >
      <div>
        <img
          src="../assets/images/noData.svg" id="order_no_data_img"
          alt=""
          class="w-300 h-170 mb-4"
        />
      </div>
      <div class="primaryColor" id="order_no_data_label">NO ORDERS FOUND</div>
    </div>

    <!-- info dialog -->

    <v-dialog
      v-model="infoDialog"
      width="650px"
      transition="scroll-y-reverse-transition"
      :retain-focus="false"
    >
      <v-card class="pa-5">
        <v-slide-group v-model="infoItems" mandatory hide-arrows>
          <v-slide-item
            v-for="(item, i) in Items"
            :key="i"
            v-bind:value="item"
            v-slot="{ active, toggle }"
          >
            <v-btn
              depressed
              :color="active ? 'activeColor' : 'unActiveColor'"
              height="26"
              min-width="80"
              width="100px"
              class="
                rounded-sm
                fsize13
                mr-2
                text-center
                white--text
                text-capitalize
              "
              :value="item"
              :id="`${item}_menu`"
              @click="toggle"
            >
              {{ item }}
            </v-btn>
          </v-slide-item>
        </v-slide-group>
        <div class="d-flex align-baseline py-3" v-if="this.orderHistory && this.orderHistory.data">
          <button id="order_info_orderType"
            class="rounded-sm text-capitalize fsize10 px-3 py-1"
            :class="this.orderHistory.data.Action == 'B' ? 'buycolor' : 'sellcolor'"
          >
            {{ this.orderHistory.data.Action == "B" ? "BUY" : "SELL" }}
          </button>

          <div class="mx-2 fsize14" id="order_info_symbol">
            {{ this.orderHistory.data.Trsym }}
          </div>
          <div class="fsize9" id="order_info_scrip_ex">{{ this.orderHistory.data["exchange"] }}</div>
          <div class="mt-1 fsize14 primaryColor orderalign" id="order_info_scrip_nest_or_no">
            Nest.Order.No#{{ this.orderHistory.data["Nstordno"] }}
          </div>
        </div>
        <v-divider class="mx-2 mb-2"></v-divider>

        <div v-if="infoItems == 'Information'" class="height-255">
          <div class="py-2" v-if="this.orderHistory && this.orderHistory.data">
            <div class="row ma-0">
              <div class="col-6 py-4 px-4">
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="order_info_scrip_qty_label">Quantity</div>
                  <div class="fsize12 primaryColor mb-2" id="order_info_scrip_qty_val">
                    {{ this.orderHistory.data["Qty"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="order_info_scrip_price_label">Price</div>
                  <div class="fsize12 primaryColor mb-2" id="order_info_scrip_price_val">
                    {{ this.orderHistory.data["Prc"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="order_info_scrip_avg_price_label">Avg.price</div>
                  <div class="fsize12 primaryColor mb-2" id="order_info_scrip_avg_price_val">
                    {{ this.orderHistory.data["averageprice"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="order_info_scrip_trigger_price_label">Trigger price</div>
                  <div class="fsize12 primaryColor mb-2" id="order_info_scrip_trigger_price_val">
                    {{ this.orderHistory.data["triggerprice"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="order_info_scrip_order_type_label">Order type</div>
                  <div class="fsize12 primaryColor mb-2" id="order_info_scrip_order_type_val">
                    {{ this.orderHistory.data["Ordtype"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="order_info_scrip_product_label">Product</div>
                  <div class="fsize12 primaryColor mb-2" id="order_info_scrip_product_val">
                    {{ this.orderHistory.data["productcode"] }}
                  </div>
                </div>
              </div>

              <v-divider vertical></v-divider>

              <div class="col-6 py-4 px-4">
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="order_info_scrip_duration_label">Validity</div>
                  <div class="fsize12 primaryColor mb-2" id="order_info_scrip_duration_val">
                    {{ this.orderHistory.data["duration"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="order_info_scrip_exch_order_Id_label">Exch. Order ID</div>
                  <div class="fsize12 primaryColor mb-2" id="order_info_scrip_exch_order_Id_val"> 
                    {{
                      orderHistory.data.exchangeorderid == null
                        ? "NA"
                        : orderHistory.data.exchangeorderid
                    }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="order_info_scrip_time_label">Time</div>
                  <div class="fsize12 primaryColor mb-2" id="order_info_scrip_time_label">
                    {{ this.orderHistory.data["filldateandtime"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="order_info_scrip_exch_time_label">Exch. Time</div>
                  <div class="fsize12 primaryColor mb-2" id="order_info_scrip_exch_time_val">
                    {{
                      orderHistory.data.ExchTimeStamp != null
                        ? orderHistory.data.ExchTimeStamp.split(" ")[1]
                        : orderHistory.data.ExchTimeStamp
                    }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="order_info_scrip_placed_by_label">Placed by</div>
                  <div class="fsize12 primaryColor mb-2" id="order_info_scrip_placed_by_val">
                    {{ getUserId }}
                  </div>
                </div>
              </div>
              <div class="d-flex align-center justify-space-between">
                <div class="fsize12 secondaryColor mb-2 mt-1 px-4" id="order_info_scrip_reason_label">
                  Reason: &nbsp;
                </div>
                <div
                  class="fsize12 primaryColor mb-2 mt-1" id="order_info_scrip_reason_val"
                  v-if="
                    orderHistory.data.RejReason
                  "
                >
                  {{ this.orderHistory.data["RejReason"] }}
                </div>
              </div>
            </div>
          </div>
          <v-divider></v-divider>
        </div>

        <div v-if="infoItems == 'History'" class="height-255"> 
          <v-simple-table class="px-3" id="order_history_table" v-if="this.orderHistory && this.orderHistory.history">
            <thead id="order_history_table_thead">
              <tr id="order_history_table_thead_tr">
                <th class="text-left fsize12 secondColor" id="order_history_table_thead_time">Time</th>
                <th class="text-center fsize12 secondColor" id="order_history_table_thead_status">Status</th>
                <th class="text-center fsize12 secondColor" id="order_history_table_thead_exch_time">Exch. time</th>
                <th class="text-center fsize12 secondColor" id="order_history_table_thead_exch_update">Exch. update</th>
                <th class="text-center fsize12 secondColor" id="order_history_table_thead_qty">Qty</th>
                <th class="text-center fsize12 secondColor" id="order_history_table_thead_filled_qty">Filled qty</th>
                <th class="text-center fsize12 secondColor" id="order_history_table_thead_Avg_prc">Avg. price</th>
                <th class="text-center fsize12 secondColor" id="order_history_table_thead_prc">Price</th>
              </tr>
            </thead>
            <tbody id="order_history_table_tbody">
              <tr id="order_history_table_tbody_tr" v-for="(item, id) of this.orderHistory.history" :id="id">
                <td class="text-left fsize12 primaryColor" id="order_history_table_tbody_exch_time">
                  {{
                    item.ExchTimeStamp != null
                      ? item.ExchTimeStamp.split(" ")[1]
                      : item.ExchTimeStamp
                  }}
                </td>
                <td class="text-center fsize12 primaryColor" id="order_history_table_tbody_status">
                  {{ item.Status }}
                </td>
                <td class="text-center fsize12 primaryColor" id="order_history_table_tbody_exchangeTime">
                  {{
                    item.exchangetimestamp != null
                      ? item.exchangetimestamp.split(" ")[1]
                      : item.exchangetimestamp
                  }}
                </td>
                <td class="text-center fsize12 primaryColor" id="order_history_table_tbody_filldatetime">
                  {{ item.filldateandtime }}
                </td>
                <td class="text-center fsize12 primaryColor" id="order_history_table_tbody_qty">
                  {{ item.Qty }}
                </td>
                <td class="text-center fsize12 primaryColor" id="order_history_table_tbody_filledshres">
                  {{ item.filledShares }}
                </td>
                <td class="text-center fsize12 primaryColor" id="order_history_table_tbody_averagePrice">
                  {{ item.averageprice }}
                </td>
                <td class="text-center fsize12 primaryColor" id="order_history_table_tbody_price">
                  {{ item.Prc }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
        <div class="row pt-4 ma-0 d-flex justify-end">
          <v-btn id="order_info_close_btn"
            depressed
            outlined
            class="text-capitalize"
            width="60px"
            height="32px"
            @click="infoDialog = false"
            >Close</v-btn
          >
        </div>
      </v-card>
    </v-dialog>

    <!-- cancel order popup-->

    <v-dialog v-model="multipleCancelDialog" width="700px">
      <v-expand-transition>
        <v-card class="pa-5" id="multiple_cancel_card">
          <div class="pb-4" id="multiple_cancel_card_label">Cancel Order</div>
          <div class="">
            <v-simple-table class="border-ededed" id="multiple_cancel_order_table">
              <thead id="multiple_cancel_order_table_thead">
                <tr id="multiple_cancel_order_table_thead_tr">
                  <th id="multiple_cancel_order_table_thead_empty"></th>
                  <th class="text-left" id="multiple_cancel_order_table_thead_Inst"><span>Instrument</span></th>
                  <th class="text-right" id="multiple_cancel_order_table_thead_qty"><span>Qty</span></th>
                  <th class="text-center" id="multiple_cancel_order_table_thead_price"><span>Price</span></th>
                  <th class="text-left" id="multiple_cancel_order_table_thead_type"><span>Type</span></th>
                  <th class="text-left" id="multiple_cancel_order_table_thead_product"><span>Product</span></th>
                  <th class="text-left" id="multiple_cancel_order_table_thead_valitity"><span>Validity</span></th>
                </tr>
              </thead>
              <tbody id="multiple_cancel_order_table_tbody">
                <tr v-for="(item, index) in this.selectedCheckbox" :key="index" :id="`${index}_multiple_cancel_order_table_tbody_tr`">
                  <td class="text-center" :id="`${index}_multiple_cancel_order_table_tbody_status`">
                    <span class="cancelled-msg px-2 py-1 rounded fsize11"
                      >CANCEL</span
                    >
                  </td>

                  <td class="text-left" :id="`${index}_multiple_cancel_order_table_tbody_scrip_name`">
                    {{
                      item.formatScripName == null
                        ? item.Trsym
                        : item.formatScripName
                    }}
                    <span class="fsize10 secondaryColor" :id="`${index}_multiple_cancel_order_table_tbody_exch`">{{
                      item.Exchange
                    }}</span>
                  </td>

                  <td class="text-right" :id="`${index}_multiple_cancel_order_table_tbody_qty`">
                    <span>{{ item.Qty }}</span>
                  </td>

                  <td class="text-center" :id="`${index}_multiple_cancel_order_table_tbody_avgPrc`">
                    <span>{{
                      item.Exchange == "CDS" || item.Exchange == "BCD"
                      ? parseFloat(Number(item.Avgprc) == 0 ? item.Prc : item.Avgprc).toFixed(4)
                        : parseFloat(Number(item.Avgprc) == 0 ? item.Prc : item.Avgprc).toFixed(2)
                    }}</span>
                  </td>

                  <td class="text-left" :id="`${index}_multiple_cancel_order_table_tbody_prctype`">
                    <span>{{ item.Prctype }}</span>
                  </td>

                  <td class="text-left" :id="`${index}_multiple_cancel_order_table_tbody_pcode`">
                    <span>{{ item.Pcode }}</span>
                  </td>

                  <td class="text-left" :id="`${index}_multiple_cancel_order_table_tbody_valitity`">
                    <span>DAY</span>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <div class="text-right pt-4">
            <v-btn id="multiple_cancel_btn"
              height="32"
              min-width="80"
              depressed
              :color="$store.state.buttonThemeColor"
              class="fsize14 mr-2 text-capitalize white--text"
              :loading="singleCancelLoader"
              :disabled="singleCancelLoader"
              @click="cancelOrder(selectedCheckbox, 'multiple')"
              >Confirm</v-btn
            >
            <v-btn id="multiple_close_btn"
              outlined
              height="32"
              min-width="80"
              color="#9b9b9b"
              depressed
              class="fsize14 text-capitalize secondaryColor"
              @click="$store.commit('order/setMultipleCancelDialog', false)"
            >
              Close
            </v-btn>
          </div>
        </v-card>
      </v-expand-transition>
    </v-dialog>

    <!-- single order cancel popup-->

    <v-dialog
      class="singleCancelDialog"
      v-model="singleCancelDialog"
      width="500px"
    >
      <v-expand-transition>
        <v-card class="pa-5" id="single_dialog_card">
          <div class="pb-4 fsize16 font-weight-bold" id="single_dialog_card_label">Cancel the Order?</div>
          <div class="text-left font-weight-bold mb-1 fsize14" id="single_dialog_card_scrip_name">
            {{
              cancelarray.formatScripName == null
                ? cancelarray.Trsym
                : cancelarray.formatScripName
            }}
          </div>
          <div class="text-left fsize13" id="single_dialog_card_scrip_nest_or_no">#{{ cancelarray.Nstordno }}</div>
          <div class="text-right pt-4">
            <v-btn id="single_confirm_btn"
              height="32"
              min-width="80"
              depressed
              :loading="singleCancelLoader"
              :disabled="singleCancelLoader"
              :color="$store.state.buttonThemeColor"
              class="fsize14 text-capitalize white--text"
              @click="cancelOrder(cancelarray, 'single')"
              >Confirm</v-btn
            >
            <v-btn id="single_close_btn"
              outlined
              height="32"
              min-width="80"
              depressed
              class="ml-2 fsize14 text-capitalize"
              @click="$store.commit('order/setSingleCancelDialog', false)"
            >
              Close
            </v-btn>
          </div>
        </v-card>
      </v-expand-transition>
    </v-dialog>
    <bottomsheet :currentData="currentSheetData" v-on:from-child ="getChildData"/>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import orderWindowjs from "../mixins/orderWindow";
import commonFunc from "../mixins/commonFunctions";
import bottomsheet from '../components/bottomSheet.vue'
import customIcon from '../components/customIcon.vue'
export default {
  mixins: [commonFunc, orderWindowjs],
  data: () => ({
    searchorder: "",
    selectedCheckbox: [],
    infoDialog: false,
    Items: ["Information", "History"],
    infoItems: "Information",
    isDropped: false,
    currentIndex: -1,
    cancelarray: [],
    isRotate: false,
    currentSheetData: []
  }),
  components:{
    bottomsheet,
    customIcon
  },

  computed: {
    ...mapState("order", [
      "pendingOrderList",
      "completedOrderList",
      "tradeBookList",
      "showOrderlist",
      "tabList",
      "orderHistory",
      "orderLoader",
      "singleCancelLoader",
      "cancelMultiple",
    ]),
    ...mapGetters("order", ["getTradeList", "getInfoType"]),
    ...mapGetters("position", [{ getInfoTypePosition: "getInfoType" }]),
    ...mapGetters("position", [{ getInfoTypePosition: "getInfoType" }]),
    ...mapGetters("authentication", ["getUserId"]),
    ...mapGetters("order", [
      "getSingleCancelDialog",
      "getMultipleCancelDialog",
    ]),

    infoType: {
      get: function () {
        return this.getInfoType;
      },
      set: function (value) {
        if (value == "Pending") {
          this.selectedCheckbox = [];
        }
        localStorage.setItem("orderTab", JSON.stringify(value));
        return this.$store.commit("order/setTabActive", value);
      },
    },

    checkBoxSelectAll: {
      get: function () {
        this.$store.commit("order/setMultiple", this.selectedCheckbox.length);
        return this.showOrderlist
          ? this.selectedCheckbox.length == this.showOrderlist.length
          : false;
      },
      set: function (value) {
        var selected = [];
        if (value) {
          this.showOrderlist.forEach(function (item) {
            selected.push(item);
          });
        } else {
          this.cancelMultiple == 0 ? (this.selectedCheckbox = []) : "";
        }
        this.selectedCheckbox = selected;
      },
    },

    orderSearchFilter() {
      return this.showOrderlist.filter((post) => {
        return this.getInfoType == "Trade Book"
          ? post.Tsym && post.Tsym.toLowerCase().includes(this.searchorder.toLowerCase())
          : post.Trsym && post.Trsym.toLowerCase().includes(this.searchorder.toLowerCase());
      });
    },

    singleCancelDialog: {
      get() {
        return this.getSingleCancelDialog;
      },
      set(value) {
        if (!value) {
          this.$store.commit("order/setSingleCancelDialog", value);
        }
        return value;
      },
    },

    multipleCancelDialog: {
      get() {
        return this.getMultipleCancelDialog;
      },
      set(value) {
        if (!value) {
          this.$store.commit("order/setMultipleCancelDialog", value);
          return value;
        }
      },
    },
  },

  methods: {
     getChildData(data){
      if(data['page'] == 'Order'){
        const index = this.showOrderlist.indexOf(data.item)
        if(data['action'] == 'buy' || data['action'] == 'sell' || data['action'] == 'modify'){
        data['action'] == 'modify' ? this.callOrderWindow(data['item'].Trantype == 'B' ? 'buy' : 'sell' ,data.item, data['action']) :  this.callOrderWindow(data['action'] , data.item)
        }
         if(data['action'] == "cancel"){
          this.cancelOrderPopup(data.item, index)
        }
         if(data['action'] == 'info'){
          this.getInfo(data.item, index)
        }
        if(data['action'] == "repeat") {
          this.callOrderWindow(data['item'].Trantype == 'B' ? 'buy' : 'sell',
                          data['item'], 'repeat')
        }
      }
    },
    callBottomSheet(val){
         this.currentSheetData = []
      var tempData = {
        where: 'Order',
        isOpen: true,
        item: val,
        tab: this.infoType
      }
      this.currentSheetData.push(tempData)
    },
    getWindowHeight(id) {
      var offsetHeight =
        this.$store.state.windowHeight -
        document.getElementById(id).getBoundingClientRect().top;
      var dropdownHeight = 125;
      offsetHeight < dropdownHeight
        ? (this.isRotate = true)
        : (this.isRotate = false);
    },
    getInfo(item, index) {
      this.$store.dispatch("order/getOrderHistory", item);
      this.infoDialog = true;
      this.infoItems = 0;
    },
    async callOrderWindow(orderType, value, type) {
      this.$store.commit("orderWindow/setWindowLoading", true);
      if(type == 'modify') {
        this.$store.commit('orderWindow/setOrderWindowModify', orderType)
      } else if (type == 'repeat') {
        this.$store.commit('orderWindow/setOrderWindowRepeat', orderType)
      } else {
        this.$store.commit("orderWindow/setOrderWindow", orderType);
      }
      orderType == "buy" ? false : true;
      this.$store.commit("orderWindow/setCurrentOrder", {
        data: value,
        page: "orders",
      });
      this.$store.dispatch("orderWindow/setInitialValues", {
        data: value,
        page: "orders",
        type: type == "modify" || type == 'repeat' ? true : false,
      });
      this.$store.commit("orderWindow/setOrderType", orderType);
      this.changeOrderTab();
    },

    cancelOrderPopup(val) {
      this.cancelarray = val;
      this.$store.commit("order/setSingleCancelDialog", true);
    },
    async cancelOrder(val, type) {
      
      if (type == "single") {
        await val.Pcode == 'BO' ? this.$store.dispatch("order/cancelBraketOrder", val): val.Pcode == 'CO' ? this.$store.dispatch("order/cancelCoverOrder", val) : this.$store.dispatch("order/cancelOrder", val);
      } else {
        for (let item of this.selectedCheckbox) {
          await item.Pcode == 'BO' ? this.$store.dispatch("order/cancelBraketOrder", item): item.Pcode == 'CO' ? this.$store.dispatch("order/cancelCoverOrder", item) : this.$store.dispatch("order/cancelOrder", item);
        }
      }
    },
  },
  watch: {
    cancelMultiple: function (val) {
      if (val == 0) {
        this.selectedCheckbox = [];
      }
    },
  },

  async created() {
    await this.$store.dispatch("order/getOrderList");
    var localTab = JSON.parse(localStorage.getItem("orderTab"));
    this.$route.query.tab
      ? this.$store.commit("order/setTabActive", this.$route.query.tab)
      : this.$store.commit("order/setTabActive", localTab);
  },
};
</script>